import { Component, inject, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionComponent } from '../../../questionnaire-builder/question/question.component'
import { Questionnaire, QuestionnaireItem } from '../../../questionnaire-builder/models/fhir-interfaces'
import { EnableWhenService, enableWhenSlideAnimation } from '@client-workspace/fhir-engine'

@Component({
  selector: 'app-questionnaire-renderer',
  standalone: true,
  imports: [CommonModule, QuestionComponent, CommonModule],
  templateUrl: './questionnaire-renderer.component.html',
  styleUrl: './questionnaire-renderer.component.scss',
  animations: [enableWhenSlideAnimation],
})
export class QuestionnaireRendererComponent implements OnInit {
  @Input() questions: QuestionnaireItem[] = []
  @Input() title = ''
  @Input() questionnaire: Questionnaire = {
    language: '',
    name: '',
    resourceType: 'Questionnaire',
    status: 'draft',
    item: [],
    title: '',
  }
  private enableWhenService = inject(EnableWhenService)
  private visibilities: { [key: string]: boolean } = {}

  ngOnInit(): void {
    this.enableWhenService.initialize(this.questionnaire.item!)

    this.enableWhenService.visibilityChanges$.subscribe((visibility) => {
      this.visibilities = visibility
    })
  }

  isVisible(linkId: string, item: QuestionnaireItem): boolean {
    if (item.enableWhen?.length) {
      return this.visibilities[linkId]
    }
    return true
  }
}
