import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterLink } from '@angular/router'
import { Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'
import { QuestionnaireRendererComponent } from '../questionnaire-renderer/questionnaire-renderer.component'
import { IconTreatmentOutlineComponent } from '@client-workspace/icons/treatment_outline.component'
import { Questionnaire } from 'fhir/r4'

@Component({
  selector: 'app-workflow-list',
  standalone: true,
  imports: [CommonModule, RouterLink, QuestionnaireRendererComponent, IconTreatmentOutlineComponent],
  template: ` <div class="wrapper">
    <section class="workflows-list">
      <button class="btn-primary small" [routerLink]="['/questionnaire-list/new']">
        Create New Questionnaire List
      </button>

      @for (item of workflows; track item.id) {
        <article class="item" data-testid="workflow-item">
          <div class="icon">
            <app-icon-treatment-outline></app-icon-treatment-outline>
          </div>

          <h2 class="name">{{ item.name }}</h2>
          <button class="btn-tertiary x-small" (click)="loadWorkflow(item.id!)">Preview</button>
          <button class="btn-secondary x-small" [routerLink]="['/questionnaire-list/', item.id, 'edit']">Edit</button>
          <button class="btn-primary x-small" [routerLink]="['/questionnaire-list/', item.id]">View</button>
          <button class="btn-warning x-small" (click)="deleteWorkflow(item.id!)">Delete</button>
        </article>
      }
    </section>

    <section class="workflow-renderer">
      @if (workflow) {
        @for (questionnaire of workflow.items; track $index) {
          <app-questionnaire-renderer
            class="is-outter"
            [questions]="questionnaire.item ?? []"
            [title]="questionnaire.title ?? ''"
            [questionnaire]="questionnaire"
          ></app-questionnaire-renderer>
        }
      }
    </section>
  </div>`,
  styles: `
    .wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }

    .workflows-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      box-sizing: border-box;
    }

    .item {
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border-radius: 6px;
      background-color: var(--white-100);
      box-shadow: 0px 2px 16px 0px rgba(1, 52, 118, 0.16);
    }

    .icon {
      position: relative;
      fill: var(--color-grey-500);
    }

    .name {
      margin-right: auto;
    }

    .workflow-renderer {
      margin-block: 2rem;
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      background-color: var(--white-100);
    }
  `,
})
export class WorkflowListComponent implements OnInit {
  workflows: Workflow[] = []
  workflow: Workflow | undefined = undefined

  ngOnInit(): void {
    this.loadWorkflows()
  }

  async loadWorkflows() {
    try {
      const response = await fetch(`/services/crud-playground/generic/workflows`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()

      this.workflows = data.map(
        (q: {
          id: string
          body: {
            title: string
            items: Questionnaire[]
            language: string
            resourceType: string
            status: string
            name: string
          }
        }) => {
          return { id: q.id, ...q.body }
        },
      )
    } catch (error) {
      console.log(error)
    }
  }

  async deleteWorkflow(id: string) {
    const data = await fetch(`/services/crud-playground/generic/workflows/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    })

    if (data.status === 204) {
      this.loadWorkflows()
    }
  }

  async loadWorkflow(id: string) {
    try {
      const response = await fetch(`/services/crud-playground/generic/workflows/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      console.log(data)

      if (data) {
        this.workflow = data
      }
    } catch (error) {
      console.log(error)
    }
  }
}
