import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { animate, style, transition, trigger } from '@angular/animations'
import { QuestionnaireItem, QuestionnaireItemEnableWhen } from 'fhir/r4'

export const enableWhenSlideAnimation = trigger('slideDownUp', [
  transition(':enter', [style({ height: 0, opacity: 0 }), animate(200)]),
  transition(':leave', [animate(200, style({ height: 0, opacity: 0 }))]),
])

@Injectable({
  providedIn: 'root',
})
export class EnableWhenService {
  private enableWhenMap = new Map<string, QuestionnaireItemEnableWhen[]>()
  private answers = new Map<string, string | string[]>()
  private visibilitySubject = new BehaviorSubject<{ [key: string]: boolean }>({})
  visibilityChanges$ = this.visibilitySubject.asObservable()

  initialize(questionnaireItems: QuestionnaireItem[]): void {
    this.processQuestionnaireItems(questionnaireItems)
  }

  updateAnswer(linkId: string, newValue: string | string[]): void {
    this.answers.set(linkId, newValue)
    this.checkVisibility()
  }

  private processQuestionnaireItems(items: QuestionnaireItem[]): void {
    items.forEach((item: QuestionnaireItem) => {
      if (item.enableWhen && item.enableWhen.length) {
        this.enableWhenMap.set(item.linkId, item.enableWhen)
      }

      if (item.item?.length) {
        this.processQuestionnaireItems(item.item)
      }
    })
  }

  private checkVisibility(): void {
    const visibility: { [key: string]: boolean } = {}

    this.enableWhenMap.forEach((rules, linkId) => {
      visibility[linkId] = rules.every((rule: QuestionnaireItemEnableWhen) => {
        const answer = this.answers.get(rule.question)

        if (!answer) {
          return false
        }

        const expectedValue =
          rule.answerString ??
          rule.answerDecimal ??
          rule.answerInteger ??
          rule.answerDate ??
          rule.answerDateTime ??
          rule.answerTime ??
          rule.answerBoolean ??
          rule.answerCoding?.code ??
          rule.answerCoding?.display ??
          rule.answerQuantity?.value

        if (!expectedValue) {
          return false
        }

        if (Array.isArray(answer)) {
          // answer.forEach((element) => {
          //   let check = this.evaluateCondition(element, rule.operator, expectedValue)
          //   //TODO: enableBehavior
          //   //if enablebehavior === any
          //   //else
          // })
          return answer.some((answer) => this.evaluateCondition(answer, rule.operator, expectedValue))
        }

        if (rule.answerString) {
          return this.evaluateCondition(answer, rule.operator, expectedValue)
        } else if (rule.answerDecimal) {
          return this.evaluateCondition(parseFloat(answer), rule.operator, expectedValue)
        } else if (rule.answerInteger) {
          return this.evaluateCondition(parseInt(answer), rule.operator, expectedValue)
        } else if (rule.answerDate) {
          return this.evaluateCondition(new Date(answer), rule.operator, new Date(expectedValue.toString()))
        } else if (rule.answerDateTime) {
          return this.evaluateCondition(new Date(answer), rule.operator, new Date(expectedValue.toString()))
        } else if (rule.answerTime) {
          return this.evaluateCondition(new Date(answer), rule.operator, new Date(expectedValue.toString()))
        } else if (rule.answerBoolean) {
          return this.evaluateCondition(JSON.parse(answer), rule.operator, expectedValue)
        } else if (rule.answerCoding) {
          return this.evaluateCondition(answer, rule.operator, expectedValue)
        } else if (rule.answerQuantity) {
          return this.evaluateCondition(parseFloat(answer), rule.operator, expectedValue)
        }
        return false
      })
    })
    this.visibilitySubject.next(visibility)
  }

  private evaluateCondition(
    answer: string | boolean | number | Date,
    operator: string,
    expected: string | boolean | number | Date,
  ): boolean {
    if (answer instanceof Date && expected instanceof Date) {
      answer = answer.getTime()
      expected = expected.getTime()
      if (isNaN(answer) || isNaN(expected)) {
        return false
      }
    }

    switch (operator) {
      case 'exists':
        return answer !== undefined
      case '=':
        return answer === expected
      case '!=':
        return answer !== expected
      case '>':
        return answer > expected
      case '<':
        return answer < expected
      case '<=':
        return answer <= expected
      case '>=':
        return answer >= expected
      default:
        return false
    }
  }
}
