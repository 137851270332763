import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IconCircleCloseFilledComponent } from '@client-workspace/icons/circle_close_filled.component'
import { IconCircleErrorFilledComponent } from '@client-workspace/icons/circle_error_filled.component'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { IconCircleInfoFilledComponent } from '@client-workspace/icons/circle_info_filled.component'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { Toast } from '../toasts/toasts.component'
import { TranslateModule } from '@ngx-translate/core'

export type ToastType = 'success' | 'warning' | 'error' | 'info'

@Component({
  selector: 'lib-toast',
  standalone: true,
  imports: [
    IconCircleCloseFilledComponent,
    IconCircleErrorFilledComponent,
    IconCircleCheckFilledComponent,
    IconCircleInfoFilledComponent,
    IconCloseFilledComponent,
    TranslateModule,
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnInit {
  closeTimeout!: ReturnType<typeof setTimeout>

  @Input() data: Toast = { id: '', type: 'info' }
  @Output() closeToast = new EventEmitter<string>()

  ngOnInit() {
    this.closeTimeout = setTimeout(() => {
      this.handleCloseToast()
    }, 4000)
  }

  handleCloseToast() {
    this.closeToast.emit(this.data.id)
    clearTimeout(this.closeTimeout)
  }
}
