<div class="wrapper">
  <section
    class="canvas"
    id="questionnaire-canvas"
    (drop)="dropHandler($event)"
    (dragover)="dragoverHandler($event)"
    (dragenter)="dragenterHandler($event)"
    (dragleave)="dragleaveHandler($event)"
  >
    @if (questionnaire) {
      <app-editor-renderer
        class="is-outter"
        data-testid="questionnaire-renderer"
        [questions]="questionnaire || []"
        [title]="properties.title"
        (editQuestion)="handleEditQuestion($event)"
      ></app-editor-renderer>
    } @else if (workflow) {
      @for (workflowItem of workflow.items; track $index) {
        <app-editor-renderer
          [attr.id]="workflowItem.id"
          data-testid="workflow-renderer"
          class="is-outter"
          [questions]="workflowItem.item ?? []"
          [title]="workflowItem.title ?? ''"
          (editQuestion)="handleEditQuestion($event)"
        ></app-editor-renderer>
      }
    }
  </section>

  <div class="palette">
    <h3 class="heading-xs">Inhalt</h3>
    <section class="field-wrapper">
      @for (item of contentItems; track item.displayName + $index) {
        <div class="palette-item" draggable="true" (dragstart)="dragstartHandler($event, item)">
          {{ item.displayName }}
        </div>
      }
    </section>
    <cc-divider></cc-divider>
    <h3 class="heading-xs">Formularfelder</h3>
    <section class="field-wrapper">
      @for (item of formItems; track item.displayName + $index) {
        <div class="palette-item form-field" draggable="true" (dragstart)="dragstartHandler($event, item)">
          {{ item.displayName }}
        </div>
      }
    </section>
  </div>
</div>

@if (properties.id) {
  <button class="btn-primary small" (click)="handleUpdateQuestionnaire()">Dokument updaten</button>
  <button class="btn-secondary small ml-10" (click)="handleSaveQuestionnaire()" [disabled]="!properties.name">
    Als Neues Dokument speichern
  </button>
} @else {
  <button class="btn-primary small" (click)="handleSaveQuestionnaire()" [disabled]="!properties.name">
    Dokument speichern
  </button>
}
