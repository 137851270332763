import { patchState, signalStore, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals'
import { FormPackagesService, Package, Packages } from '../../../generated'
import { computed, inject } from '@angular/core'
import { rxMethod } from '@ngrx/signals/rxjs-interop'
import { concatMap, pipe, switchMap, tap } from 'rxjs'
import { tapResponse } from '@ngrx/operators'
import { withDevtools } from '@angular-architects/ngrx-toolkit'
import { addEntity, removeAllEntities, setAllEntities, withEntities } from '@ngrx/signals/entities'
import { Questionnaire } from 'fhir/r4'

type FormPackagesState = {
  selectedPackageId: string
  packagesLoading: boolean
  formsLoading: boolean
  formsLoadingFinished: boolean
}

const initialState: FormPackagesState = {
  selectedPackageId: '',
  packagesLoading: false,
  formsLoading: false,
  formsLoadingFinished: false,
}

// TODO: Use our own real type, entity manager requires id
interface FhirQuestionnaire extends Questionnaire {
  id: string
}

export const FormPackagesStore = signalStore(
  { providedIn: 'root' },
  // State
  withState(initialState),
  withEntities({ entity: type<FhirQuestionnaire>(), collection: 'forms' }),
  withEntities({ entity: type<Package>(), collection: 'packages' }),
  // Selectors
  withComputed(({ packagesEntities, formsEntities, selectedPackageId }) => ({
    countPackages: computed(() => packagesEntities()?.length),
    countForms: computed(() => formsEntities()?.length),
    selectedPackage: computed(() => packagesEntities().find((p) => p.id === selectedPackageId())),
  })),
  // Updater
  withMethods((store, formPackagesService = inject(FormPackagesService)) => ({
    loadPackages: rxMethod<string>(
      pipe(
        tap(() => patchState(store, { packagesLoading: true })),
        switchMap(() =>
          formPackagesService.getFormPackages().pipe(
            tapResponse({
              next: ({ packages }: Packages) =>
                patchState(store, setAllEntities(packages, { collection: 'packages' }), { packagesLoading: false }),
              error: (err) => {
                patchState(store, { packagesLoading: false })
                console.error(err)
              },
            }),
          ),
        ),
      ),
    ),
    loadFormById: rxMethod<{ id: string }>(
      pipe(
        tap(() => patchState(store, { formsLoading: true, formsLoadingFinished: false })),
        concatMap(({ id }) =>
          formPackagesService.getFormById(id).pipe(
            tapResponse({
              next: (form: FhirQuestionnaire) => patchState(store, addEntity(form, { collection: 'forms' })),
              error: (err) => {
                patchState(store, { formsLoading: false })
                console.error(err)
              },
            }),
          ),
        ),
        tap(() => patchState(store, { formsLoading: false, formsLoadingFinished: true })),
      ),
    ),
  })),
  // Effects
  withMethods((store, formPackageService = inject(FormPackagesService)) => ({
    selectPackage: ({ id }: { id: string }) => {
      patchState(store, { selectedPackageId: id })
      const packages = store.packagesEntities()
      const selectedPackage = packages ? packages.find((p) => p.id === id) : undefined
      selectedPackage?.forms.map((f) => store.loadFormById({ id: f.id }))
    },
    reset: () => {
      patchState(store, removeAllEntities({ collection: 'forms' }))
      patchState(store, { selectedPackageId: '' })
    },
  })),
  withHooks({
    onInit({ loadPackages }) {
      loadPackages('')
    },
    onDestroy() {
      console.log('on destroy')
    },
  }),
  withDevtools('formPackages'),
)
