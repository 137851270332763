import { Questionnaire } from 'fhir/r4'

export const initialAdmissionQuestionnaireDummy: Questionnaire = {
  language: 'de-DE',
  resourceType: 'Questionnaire',
  status: 'draft',
  name: '',
  title: 'Angaben über die zu behandelnde Person',
  item: [
    {
      linkId: 'Cj5CLY',
      text: 'Persönliche Angaben',
      type: 'group',
      item: [
        {
          linkId: 'xplshi',
          text: 'Patienteninformationen',
          type: 'group',
          item: [
            {
              linkId: 'JyAw1B',
              text: 'Vorname',
              type: 'string',
              required: false,
              definition: 'firstName',
              extension: [
                {
                  url: 'https://caas.thieme.de/fhir/url_interface',
                  valueCode: 'fn',
                },
              ],
            },
            {
              linkId: 'M9UlCZ',
              text: 'Nachname',
              type: 'string',
              extension: [
                {
                  url: 'https://caas.thieme.de/fhir/url_interface',
                  valueCode: 'ln',
                },
              ],
            },
            {
              linkId: 'um9kfz',
              text: 'E-Mail',
              type: 'string',
              extension: [
                {
                  url: 'https://caas.thieme.de/fhir/url_interface',
                  valueCode: 'email',
                },
              ],
            },
          ],
        },
        {
          linkId: 'JJzL2o',
          text: 'Hausarzt',
          type: 'group',
          item: [
            {
              linkId: '71721Y',
              text: 'Vorname',
              type: 'string',
            },
            {
              linkId: 'g2WE0u',
              text: 'Nachname',
              type: 'string',
            },
            {
              linkId: '25VlvV',
              text: 'Email',
              type: 'string',
            },
          ],
        },
        {
          linkId: '3qDC6S',
          text: 'Versicherung',
          type: 'group',
          item: [
            {
              linkId: 'nJ0Fiq',
              text: 'Wie sind Sie versichert?',
              type: 'choice',
              answerOption: [
                {
                  id: 'privat',
                  valueCoding: {
                    code: '',
                    display: 'Privat',
                    system: 'privat',
                  },
                },
                {
                  id: 'gesetzlich',
                  initialSelected: true,
                  valueCoding: {
                    display: 'Gesetzlich',
                    system: 'gesetzlich',
                  },
                },
              ],
            },
            {
              linkId: 'lOjzvT',
              text: 'Name der Versicherung',
              type: 'string',
              extension: [
                {
                  url: 'https://caas.thieme.de/fhir/url_interface',
                  valueCode: 'insuranceName',
                },
              ],
            },
            {
              linkId: '1RJKmY',
              text: 'Haben Sie eine Zusatzversicherung?',
              type: 'boolean',
            },
          ],
        },
        {
          linkId: '84VnZE',
          text: 'Berufsgenossenschafts-Fall',
          type: 'group',
          item: [
            {
              linkId: '4zCfs1',
              text: 'Handelt es sich bei Ihrem Fall um einen Berufsgenossenschafts-Fall?',
              type: 'boolean',
            },
          ],
        },
      ],
    },
    {
      linkId: 'NRNAIe',
      text: 'Angaben zum Aufenthalt',
      type: 'group',
      item: [
        {
          linkId: 'gJeiES',
          text: 'Patienten-ID und Fallnummer',
          type: 'group',
          item: [
            {
              linkId: 'HlyMkv',
              text: 'Patienten-ID',
              type: 'string',
              extension: [
                {
                  url: 'https://caas.thieme.de/fhir/url_interface',
                  valueCode: 'id',
                },
              ],
            },
            {
              linkId: 'bmlhbD',
              text: 'Fallnummer',
              type: 'string',
              extension: [
                {
                  url: 'https://caas.thieme.de/fhir/url_interface',
                  valueCode: 'cn',
                },
              ],
            },
          ],
        },
        {
          linkId: '55Lnns',
          text: 'Aufenthalt',
          type: 'group',
          item: [
            {
              linkId: 'jHl5TF',
              text: 'Um welche Art von Aufenthalt handelt es sich?',
              type: 'choice',
              required: true,
              answerOption: [
                {
                  id: 'ambulant',
                  valueCoding: {
                    display: 'Ambulant',
                    system: 'ambulant',
                  },
                },
                {
                  id: 'stationär',
                  initialSelected: true,
                  valueCoding: {
                    display: 'Stationär',
                    system: 'stationär',
                  },
                },
              ],
            },
            {
              linkId: 'Jz3jLG',
              text: 'Station',
              type: 'string',
              required: true,
              extension: [
                {
                  url: 'https://caas.thieme.de/fhir/url_interface',
                  valueCode: 'ward',
                },
              ],
            },
          ],
        },
        {
          linkId: '2RvRAY',
          text: 'Begleitperson',
          type: 'group',
          item: [
            {
              linkId: 'm93zUz',
              text: 'Werden Sie durch eine Person begleitet?',
              type: 'boolean',
            },
          ],
        },
        {
          linkId: 'phvnJJ',
          text: 'Gesundheitliche Risikofaktoren',
          type: 'group',
          item: [
            {
              linkId: 'WSqsVt',
              text: 'Bestehen gesundheitliche Risikofaktoren?',
              type: 'boolean',
            },
          ],
        },
      ],
    },
    /*{
      linkId: '1RJKmY',
      text: 'Wahlleistungsvereinbarungen',
      type: 'group',
      item: [
        {
          linkId: 'E-EDP9_0010006558_05-2024_a1',
          text: 'zwischen der Klinikum Frankfurt (Oder) GmbH, Müllroser Chaussee 7, 15236 Frankfurt (Oder) als Träger des Krankenhauses und',
          type: 'display',
        },
        {
          linkId: 'E-EDP9_0010006558_05-2024_a2',
          text: 'der oben genannten Patientin/dem oben genannten Patienten',
          type: 'display',
        },
        {
          linkId: 'E-EDP9_0010006558_05-2024_a3',
          text: 'über die Gewährung der nachstehenden – neben den allgemeinen Krankenhausleistungen gesondert berechenbaren Wahlleistungen (gemäß §17 KHEntgG)',
          type: 'display',
        },
        {
          linkId: 'E-EDP9_0010006558_05-2024_a4',
          text: 'Zu den in den Allgemeinen Vertragsbedingungen (AVB) des Klinikums in der jeweils gültigen Fassung und im Krankenhausentgelttarif genannten Bedingungen.',
          type: 'display',
        },
        {
          linkId: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen',
          text: 'Ich möchte für mich/den oben genannten Patienten folgende angekreuzte gesondert berechenbaren Wahlleistungen in Anspruch nehmen:',
          type: 'choice',
          required: false,
          repeats: true,
          answerOption: [
            {
              id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__aerztliche_Wahlleistungen',
              valueCoding: {
                display:
                  '1. ärztliche Wahlleistungen Die ärztlichen Leistungen aller an der Behandlung beteiligten Ärzte des Krankenhauses, soweit diese zur gesonderten Berechnung ihrer Leistungen im Rahmen der vollstationären und teilstationären sowie einer vor- und nachstationären Behandlung (§115a SGB V) berechtigt sind, einschließlich der von diesen Ärzten veranlaßten Leistungen von Ärzten und ärztlich geleiteten Einrichtungen außerhalb des Krankenhauses (§17 Abs.3 S.1 KHEntgG). Dies gilt auch, soweit die wahlärztlichen Leistungen vom Krankenhaus berechnet werden; die Liquidation erfolgt nach der GOÄ in der jeweils gültigen Fassung.',
              },
            },
            {
              id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Unterbringung_1-Bett-Zimmer',
              valueCoding: {
                display: '2. Unterbringung in einem 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag',
              },
            },
            {
              id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Unterbringung_2-Bett-Zimmer',
              valueCoding: {
                display: '3. Unterbringung in einem 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag',
              },
            },
            {
              id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Aufnahme_Begleitperson_Regelstation',
              valueCoding: {
                display: '4. Aufnahme einer Begleitperson 28,00 EUR Zuschlag pro Berechnungstag (Regelstation)',
              },
            },
            {
              id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Aufnahme_Begleitperson_1-Bett-Zimmer_Komfortstation',
              valueCoding: {
                display:
                  '5. Aufnahme einer Begleitperson 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag (Komfortstation)',
              },
            },
            {
              id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Aufnahme_Begleitperson_2-Bett-Zimmer_Komfortstation',
              valueCoding: {
                display:
                  '6. Aufnahme einer Begleitperson 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag (Komfortstation)',
              },
            },
          ],
        },
        {
          linkId: 'E-EDP9_0010006558_05-2024_a16',
          text: 'Sollte die Unterbringung aufgrund der Bettenkapazität nicht in der von mir beantragten Kategorie erfolgen können, stimme ich, ohne dass es einer weiteren Erklärung bedarf, einer Unterbringung in der nächstniedrigeren Kategorie zu. In diesem Fall erfolgt selbstverständlich keine Berechnung des Einzelzimmerzuschlages.',
          type: 'display',
        },
      ],
    },*/
  ],
}
