import { Component, inject, Input, OnInit } from '@angular/core'

import { AsyncPipe } from '@angular/common'
import { AdmissionsEntity } from '../+state/admissions.models'
import { Observable } from 'rxjs'
import { FhirQuestionnaireResponse, QuestionnaireRendererComponent } from '@client-workspace/fhir-engine'
import { AdmissionsFacade } from '../+state/admissions.facade'
import { wahlleistungen } from '../_mocks/_CaaS_EDP09_0010006558_de-DE'
import { koraWahlleistungen } from '../_mocks/kora-wahlleistungsvereinbarung'
import { fhirQuestionnaireItemTypes } from '../_mocks/fhir-questionnaire-item-types'
import { einwilligungDigitalisierung } from '../_mocks/_CaaS_EDP02_0010006558_de-DE'
import { einwilligungEntlassmanagement } from '../_mocks/_CaaS_EDP13_0010006558_de-DE'
import { Questionnaire } from 'fhir/r4'

@Component({
  selector: 'app-process-admission',
  standalone: true,
  imports: [AsyncPipe, QuestionnaireRendererComponent],
  templateUrl: './process-admission.component.html',
  styleUrl: './process-admission.component.css',
})
export class ProcessAdmissionComponent implements OnInit {
  @Input() id!: string
  admissionFacade = inject(AdmissionsFacade)
  admission$!: Observable<AdmissionsEntity | undefined>
  wl = wahlleistungen as Questionnaire
  kwl = koraWahlleistungen as Questionnaire
  fqit = fhirQuestionnaireItemTypes as Questionnaire
  ed = einwilligungDigitalisierung as Questionnaire
  ee = einwilligungEntlassmanagement as Questionnaire

  ngOnInit(): void {
    this.admission$ = this.admissionFacade.selectAdmission(this.id)
  }

  updateQuestionnaireResponse(updatedQuestionnaireResponse: FhirQuestionnaireResponse) {
    this.admissionFacade.updateQuestionnaireResponseInAdmissionById(this.id, updatedQuestionnaireResponse)
  }
}
