<section class="new-admissions">
  <h1 class="headline heading-m m-semibold">
    {{ 'admissions.new.title' | translate }} {{ person.firstName }} {{ person.lastName }} *{{ person.birthDay }}
  </h1>
  <app-navigation-panel
    [currentFormIds]="currentFormIds"
    [finishedFormIds]="finishedFormIds"
    [admissionAvailable]="!!admissionsLength()"
    [bogenpaket]="selectedPackage()"
    (navigateToForm)="navigateToForm($event)"
    (resetQuestionnaireList)="reset()"
  ></app-navigation-panel>
  <section class="admission-list">
    @if (!admissionsLength()) {
      <div class="card-plain">
        <h2 class="heading-s m-semibold">Aufnahmeformulare auswählen</h2>
        <h4 class="text-m">Welche Formulare sollen zugewiesen werden?</h4>

        <form action="">
          @for (item of packageEntities(); track item.name) {
            <mat-checkbox
              class="wokflow-item"
              [checked]="selectedPackageId === item.id"
              (change)="handleChange($event.checked, item)"
            >
              <h3 class="title">
                {{ item.name || '-' }}
              </h3>

              <!-- <h6 class="title-content">{{ item.id }}</h6> -->
              <h6 class="title-content">{{ generatePackageString(item) }}</h6>
            </mat-checkbox>
          }
        </form>
      </div>
      <button
        class="btn-primary"
        data-testid="start-admission"
        (click)="startAdmission()"
        [disabled]="!selectedPackageId"
      >
        Aufnahme starten
      </button>
    } @else {
      @for (admission of admissions(); track $index) {
        <div class="form">
          <fhir-questionnaire-renderer
            [questionnaire]="admission"
            [questionnaireResponse]="questionnaireResponse"
            (questionnaireResponseChange)="updateQuestionnaireResponse($event)"
            (questionnaireCompletenessChange)="handleQuestionnaireCompletenessChange($event)"
          />
        </div>
      }
    }
  </section>
</section>
<section>
</section>
