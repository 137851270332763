import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { IconCirclePlusOutlineComponent } from '@client-workspace/icons/circle_plus_outline.component'
import { Bogenpaket } from '../new/new-admissions.component'
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-reset-questionnaire-list-confirmation-dialog',
  template: `
    <div class="inner-dialog">
      <div>
        <h2>
          {{ 'Auswahl ändern' | translate }}
        </h2>
        <app-icon-close-filled
          style="fill: var(--text-heading-primary)"
          mat-dialog-close
          class="dialog-close-button"
        ></app-icon-close-filled>
      </div>
      <mat-dialog-content>
        <p>Sind Sie sicher, dass Sie die Auswahl ändern möchten?</p>
      </mat-dialog-content>
      <mat-dialog-actions style="float: right">
        <button class="btn-secondary" style="margin-right: 8px;" (click)="onNoClick()">Abbrechen</button>
        <button class="btn-primary" mat-dialog-close cdkFocusInitial>Bestätigen</button>
      </mat-dialog-actions>
    </div>
  `,
  imports: [MatDialogContent, MatDialogActions, MatDialogClose, IconCloseFilledComponent, TranslatePipe],
  standalone: true,
})
export class ResetQuestionnaireListConfirmationDialogComponent {
  readonly dialogRef = inject(MatDialogRef<ResetQuestionnaireListConfirmationDialogComponent>)

  onNoClick(): void {
    this.dialogRef.close()
  }
}

@Component({
  selector: 'app-navigation-panel',
  standalone: true,
  imports: [CommonModule, IconCirclePlusOutlineComponent, IconCircleCheckFilledComponent],
  templateUrl: './navigation-panel.component.html',
  styleUrl: './navigation-panel.component.scss',
})
export class NavigationPanelComponent {
  @Input() finishedFormIds: Array<string> = [] //'E-EDPXX-0000000000']
  @Input() currentFormIds: Array<string> = [] //'E-EDP9-0010006558']
  @Input() bogenpaket: Bogenpaket | undefined = undefined
  @Input() admissionAvailable = false
  @Output() navigateToForm: EventEmitter<string> = new EventEmitter<string>()
  @Output() resetQuestionnaireList = new EventEmitter()
  readonly dialog = inject(MatDialog)

  handleNavigateToForm(event: MouseEvent, id: string) {
    event.preventDefault()
    event.stopPropagation()
    this.navigateToForm.emit(id)
  }

  confirmReset(): void {
    const dialogRef = this.dialog.open(ResetQuestionnaireListConfirmationDialogComponent)

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.resetQuestionnaireList.emit()
      }
    })
  }
}
