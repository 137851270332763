<h3 class="heading-s">Workflow-Engine</h3>
<section cdkDropListGroup class="workflow-container">
  <div class="workflow-canvas" cdkDropList (cdkDropListDropped)="onDrop($event)">
    <div class="flex-row gap-15">
      <cc-input-field id="text-name" [(value)]="workflowName" placeholder="Name des Bogens"></cc-input-field>
      <cc-input-field id="text-title" [(value)]="workflowTitle" placeholder="Titel des Bogens"></cc-input-field>
    </div>

    @for (item of workflowItems; track item.id + $index) {
      <div class="canvas-item" [class]="true ? 'is-disabled' : ''">
        {{ item.title }}
      </div>
    }
  </div>

  <div class="questionnaires-list" cdkDropList [cdkDropListConnectedTo]="['workflow-canvas']">
    <h3 class="text-m">Gespeicherte Items aus dem Builder</h3>
    @if (extractedQuestionnaire) {
      @for (item of extractedQuestionnaire; track item.id) {
        <div class="item" draggable="true" cdkDrag [cdkDragData]="item">
          <sup class="supertext text-xs"> {{ item.name }}</sup>
          <p>
            {{ item.title }}
          </p>
        </div>
      }
    }
  </div>
</section>

@if (workflowItems) {
  <button class="btn-primary" (click)="saveWorkflow()">Speichern</button>
}

@if (savedQuestionnaire) {
  <!-- <fhir-questionnaire-renderer [questionnaire]="workflow"></fhir-questionnaire-renderer> -->
}
