<label class="label-text text-xs m-semibold">{{ questionnaireItem.text }}</label>
<mat-form-field>
  <input
    matInput
    [id]="questionnaireItem.linkId"
    [placeholder]="questionnaireItem.text ?? ''"
    [formControl]="formControl"
    (change)="questionnaireItemChange.emit(formControl.getRawValue()?.toString())"
  />
</mat-form-field>
