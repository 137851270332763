import { Component, inject, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionnaireItem, Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'
import { CanvasComponent } from '../../../questionnaire-builder/canvas/canvas.component'
import { PropertiesEditorComponent } from '../../../questionnaire-builder/properties-editor/properties-editor.component'
import { WorkflowEngineFacade } from '../+state/workflow-engine.facade'
import { Option, Value } from '@client-workspace/custom-components'
import { flatten } from '../../../-shared/utils/utils'

@Component({
  selector: 'app-workflow-editor',
  standalone: true,
  imports: [CommonModule, CanvasComponent, PropertiesEditorComponent],
  template: `
    <div class="container">
      <app-canvas
        [workflow]="workflow || undefined"
        [properties]="properties"
        (saveWorkflow)="handleSaveWorkflow($event)"
        (updateQuestionnaire)="handleUpdateWorkflow()"
        (editQuestion)="handleEditQuestion($event)"
      ></app-canvas>
      <app-properties-editor
        [selectedItem]="editQuestion"
        [properties]="properties"
        [questions]="questions"
        [enableWhenQuestions]="enableWhenQuestions"
        (deleteQuestion)="handleDeleteQuestion($event)"
        (updateProperties)="handleUpdateProperties($event)"
        (updateQuestion)="handleUpdateQuestion($event)"
      ></app-properties-editor>
      -
    </div>
  `,
  styles: `
    .container {
      display: flex;
      height: 100vh;
      padding: 20px;
      box-sizing: border-box;
    }

    app-palette {
      margin-right: 20px;
    }

    app-canvas {
      flex: 1;
      margin-right: 20px;
    }

    app-properties-editor {
      width: 300px;
    }
  `,
})
export class WorkflowEditorComponent implements OnInit {
  @Input() id!: string
  workflowFacade = inject(WorkflowEngineFacade)
  workflow: Workflow | undefined = undefined
  properties: { name: string; title: string; id: string | undefined } = { name: '', title: '', id: undefined }
  editQuestion: QuestionnaireItem | undefined = undefined
  enableWhenQuestions: Option[] = []
  questions: QuestionnaireItem[] = []

  constructor() {
    this.workflowFacade.workflow$.subscribe((workflow: Workflow | undefined) => {
      this.workflow = workflow
      this.properties.name = workflow?.name || ''
      this.properties.title = workflow?.title || ''

      if (workflow?.items) {
        this.enableWhenQuestions = workflow.items
          .map((questionnaire) => {
            return flatten(questionnaire.item!)
              .filter((el) => el.type !== 'group')
              .map(
                (q) =>
                  ({
                    value: q.linkId,
                    label: q.text as Value,
                  }) as Option,
              )
          })
          .flat()
      }
    })

    this.workflowFacade.editQuestion$.subscribe((question) => {
      this.editQuestion = structuredClone(question)
    })
  }
  ngOnInit(): void {
    this.loadWorkflow(this.id)
  }

  async loadWorkflow(id: string) {
    try {
      const response = await fetch(`/services/crud-playground/generic/workflows/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      if (data) {
        this.workflowFacade.loadWorkflow(id, data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleUpdateWorkflow() {
    this.workflowFacade.updateWorkflow()
  }

  handleSaveWorkflow(workflow: Workflow) {
    this.workflowFacade.setWorkflow(workflow)
  }

  handleDeleteQuestion(item: QuestionnaireItem) {
    this.workflowFacade.deleteQuestion(item)
  }

  handleUpdateProperties({ name, title }: { name: string; title: string }) {
    this.workflowFacade.updateProperties({ name, title })
  }

  handleUpdateQuestion(item: QuestionnaireItem) {
    this.workflowFacade.updateQuestion(item)
  }

  handleAddedQuestion(item: QuestionnaireItem) {
    this.workflowFacade.updateQuestion(item)
  }

  handleEditQuestion(item: QuestionnaireItem) {
    this.workflowFacade.editQuestion(item)
  }
}
