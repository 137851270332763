import {
  Extension,
  Questionnaire,
  QuestionnaireItem,
  QuestionnaireItemEnableWhen,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
} from 'fhir/r4'

export enum FhirExtensionUrl {
  renderingCSSClass = 'https://caas.thieme.de/fhir/rendering-css-class',
  renderingStyle = 'https://hl7.org/fhir/R4/extension-rendering-style.html',
}

export type FhirQuestionnaireItemType = QuestionnaireItem['type']

export type FhirResourceType = 'Questionnaire' | 'QuestionnaireResponse'

export type FhirResourceStatus = Questionnaire['status']

export type FhirLanguage = 'de-DE' | 'en-US'

export type FhirExtension = Extension & {
  url: string
  valueCode: string
}

export type FhirQuestionnaire = Omit<Questionnaire, 'item'> & {
  item?: FhirQuestionnaireItem[]
}

export type FhirQuestionnaireItem = Omit<QuestionnaireItem, 'linkId' | 'text' | 'type' | 'item' | 'enableWhen'> & {
  linkId: string
  text: string
  type: string //TODO: can't use type, my demo questionnaire throws errors
  item?: FhirQuestionnaireItem[]
  enableWhen?: QuestionnaireItemEnableWhen[]
}

export type FhirAnswer = Omit<QuestionnaireResponseItemAnswer, 'valueDate' | 'valueTime' | 'valueDateTime'> & {
  valueDate?: Date
  valueTime?: Date
  valueDateTime?: Date
}

export type FhirQuestionnaireResponseItem = Omit<QuestionnaireResponseItem, 'answer' | 'item'> & {
  answer?: FhirAnswer[]
  item?: FhirQuestionnaireResponseItem[]
}

export type FhirQuestionnaireResponse = Omit<QuestionnaireResponse, 'item'> & {
  item: FhirQuestionnaireResponseItem[]
}

export type LinkIdValueMap = { linkId: string; value: string }
