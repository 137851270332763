import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { fhirQuestionnaireItemTypes } from '../_mocks/fhir-questionnaire-item-types'
import { koraWahlleistungen } from '../_mocks/kora-wahlleistungsvereinbarung'
import { FhirQuestionnaireResponse, QuestionnaireRendererComponent } from '@client-workspace/fhir-engine'
import { Questionnaire } from 'fhir/r4'

@Component({
  selector: 'app-fhir-questionnaire-item-type-showcase',
  standalone: true,
  imports: [CommonModule, QuestionnaireRendererComponent],
  templateUrl: './fhir-questionnaire-item-type-showcase.component.html',
  styleUrl: './fhir-questionnaire-item-type-showcase.component.scss',
})
export class FhirQuestionnaireItemTypeShowcaseComponent {
  fqit = fhirQuestionnaireItemTypes as Questionnaire
  kwl = koraWahlleistungen as Questionnaire

  updateQuestionnaireResponse($event: FhirQuestionnaireResponse) {
    console.log($event)
  }
}
