@if (admission$ | async; as admission) {
  <fhir-questionnaire-renderer
    [questionnaire]="admission.questionnaire"
    [questionnaireResponse]="admission.questionnaireResponse"
    (questionnaireResponseChange)="updateQuestionnaireResponse($event)"
  />
}
<fhir-questionnaire-renderer [questionnaire]="wl"></fhir-questionnaire-renderer>
<fhir-questionnaire-renderer [questionnaire]="ed"></fhir-questionnaire-renderer>
<fhir-questionnaire-renderer [questionnaire]="ee"></fhir-questionnaire-renderer>
