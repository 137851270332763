import { Component, EventEmitter, Input, Output } from '@angular/core'
import { QuestionnaireItem, Workflow } from '../models/fhir-interfaces'
import { v4 as uuidv4 } from 'uuid'
import { DividerComponent } from '@client-workspace/custom-components'
import { CommonModule } from '@angular/common'
import { flatten } from '../../-shared/utils/utils'
import { EditorRendererComponent } from '../editor-renderer/editor-renderer.component'

interface QuestionnaireItemTypes {
  type: string
  displayName: string
}

@Component({
  standalone: true,
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  imports: [DividerComponent, CommonModule, EditorRendererComponent],
})
export class CanvasComponent {
  @Input() questionnaire: QuestionnaireItem[] | undefined = undefined
  @Input() workflow: Workflow | undefined = undefined
  @Input() properties: { name: string; title: string; id: string | undefined } = { name: '', title: '', id: undefined }
  @Output() saveQuestions = new EventEmitter<QuestionnaireItem[]>()
  @Output() editQuestion = new EventEmitter<QuestionnaireItem>()
  @Output() saveWorkflow = new EventEmitter<Workflow>()
  @Output() updateQuestionnaire = new EventEmitter()
  @Output() saveQuestionnaire = new EventEmitter()

  /**
   * https://hl7.org/fhir/R4/valueset-item-type.html
   * Lvl	Code	Display	Definition
   * 0	️✔ group
   * 0	✔ display
   * 0	question
   * 1	  ✔ boolean
   * 1	  ✔ decimal
   * 1	  ✔ integer
   * 1	  ✔ date
   * 1	  ✔ dateTime
   * 1	  ✔ time
   * 1	  ✔ string
   * 1	  ✔ text
   * 1	  ✔ url
   * 1	  ✔ choice
   * 1	  ❌ open-choice
   * 1	  ❌ attachment
   * 1	  ❌ reference
   * 1	  ❌ quantity
   */
  contentItems: QuestionnaireItemTypes[] = [
    { type: 'group', displayName: 'Gruppe' },
    { type: 'display', displayName: 'Inhalt' },
  ]

  formItems: QuestionnaireItemTypes[] = [
    { type: 'string', displayName: 'Einzeilige Eingabe' },
    { type: 'text', displayName: 'Mehrzeilige Eingabe' },
    { type: 'boolean', displayName: 'Checkbox' },
    { type: 'date', displayName: 'Datum' },
    { type: 'dateTime', displayName: 'Datum und Uhrzeit' },
    { type: 'time', displayName: 'Uhrzeit' },
    { type: 'choice', displayName: 'Dropdown' },
    { type: 'integer', displayName: 'Ganzzahl' },
    { type: 'decimal', displayName: 'Fließkommazahl' },
    { type: 'url', displayName: 'URL' },
    // { type: 'signature', displayName: 'Unterschrift' },
  ]

  dropHandler(event: DragEvent) {
    event.preventDefault()

    const targetEl = event.target as HTMLElement
    // Remove is-hovering class
    this.removeHoveringClass(targetEl)

    let questions
    let workflow

    if (this.questionnaire) {
      questions = structuredClone(this.questionnaire || [])
    } else if (this.workflow) {
      workflow = structuredClone(this.workflow)
      const workflowId = targetEl?.closest('app-editor-renderer')?.id
      const selectedWorkflow = workflow!.items.find((workflow) => workflow.id === workflowId)
      questions = selectedWorkflow?.item
    }

    if (!questions) return

    const dataTransfer = event.dataTransfer
    let droppedItem
    if (dataTransfer) {
      droppedItem = JSON.parse(event.dataTransfer?.getData('item'))
    }
    const newItem: QuestionnaireItem = {
      linkId: uuidv4(),
      text: `${droppedItem.displayName}`,
      type: droppedItem.type,
      required: false,
      answerOptions: droppedItem.type === 'choice' ? [] : undefined,
      enableWhen: [],
    }

    const parentObj = targetEl.closest('.group-container')
    const parentObjectId = parentObj?.id ?? null

    if (!parentObj) {
      questions.push(newItem)
    } else {
      const obj = flatten(questions).find((el) => el.linkId === parentObjectId)
      if (obj) {
        obj.item = obj.item || []
      } else {
        questions.push(newItem)
      }
    }

    if (this.questionnaire) {
      this.saveQuestions.emit(questions)
    } else if (this.workflow) {
      this.saveWorkflow.emit(workflow)
    }
  }

  removeHoveringClass(targetEl: HTMLElement) {
    targetEl.classList.remove('is-hovering')
    targetEl.querySelectorAll('.group')?.forEach((el: Element) => {
      el.classList.remove('is-hovering')
    })
  }

  dragoverHandler(event: DragEvent) {
    event.preventDefault()
  }

  dragenterHandler(event: DragEvent) {
    event.preventDefault()
    const target = event.target
    if (target instanceof HTMLElement) {
      target.classList.add('is-hovering')
    }
  }

  dragleaveHandler(event: DragEvent) {
    event.preventDefault()
    const target = event.target
    if (target instanceof HTMLElement) {
      target.classList.remove('is-hovering')
    }
  }

  dragstartHandler(event: DragEvent, item: QuestionnaireItemTypes) {
    const dataTransfer = event.dataTransfer
    if (dataTransfer) {
      dataTransfer.setData('item', JSON.stringify(item))
      dataTransfer.dropEffect = 'copy'
    }
  }

  onDelete(item: QuestionnaireItem) {
    if (!this.questionnaire) return

    const index: number = this.questionnaire.indexOf(item)
    if (index !== -1) {
      this.questionnaire.splice(index, 1)
    }
  }

  isVisible(item: QuestionnaireItem): boolean {
    if (!item.enableWhen || item.enableWhen.length === 0) {
      return true
    }

    // return item.enableWhen.every((condition) => {
    return item.enableWhen.every(() => {
      // const control = this.form.get(condition.question)
      // if (!control) {
      //   return false
      // }
      // switch (condition.operator) {
      //   case '==':
      //     return control.value === condition.answerBoolean || control.value === condition.answerString
      //   case '!=':
      //     return control.value !== condition.answerBoolean && control.value !== condition.answerString
      //   default:
      //     return false
      // }
    })
  }

  handleUpdateQuestionnaire() {
    if (this.properties.id) {
      this.updateQuestionnaire.emit()
    }
  }

  handleSaveQuestionnaire() {
    this.saveQuestionnaire.emit()
  }

  handleEditQuestion(question: QuestionnaireItem) {
    this.editQuestion.emit(question)
  }
}
