import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonElementOption } from '@client-workspace/custom-components'
import { ActiveQuestionnaireItem } from '../../fhir-deserializer/types/active-questionnaire.type'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from '@ngx-translate/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatTimepickerModule } from '@angular/material/timepicker'
import { QuestionnaireItemTypeDatetimeComponent } from './questionnaire-item-type-datetime/questionnaire-item-type-datetime.component'
import { QuestionnaireItemTypeStringComponent } from './questionnaire-item-type-string/questionnaire-item-type-string.component'
import { EnableWhenService } from '@client-workspace/fhir-engine'

@Component({
  selector: 'fhir-questionnaire-item',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTimepickerModule,
    QuestionnaireItemTypeDatetimeComponent,
    QuestionnaireItemTypeStringComponent,
  ],
  providers: [MatDatepickerModule],
  templateUrl: './questionnaire-item.component.html',
  styleUrl: './questionnaire-item.component.scss',
})
export class QuestionnaireItemComponent implements OnInit {
  @Input() questionnaireItem!: ActiveQuestionnaireItem
  @Output() emitQuestionnaireItemChange = new EventEmitter()
  @Input() visibilities: { [key: string]: boolean } = {}
  value = ''
  formControl = new FormControl('')
  enableWhenService = inject(EnableWhenService)

  ngOnInit(): void {
    if (this.questionnaireItem) {
      this.value = this.questionnaireItem.answer?.getAnswer() as string
      this.formControl.setValue(this.value)
      if (['group', 'display'].indexOf(this.questionnaireItem.type) > 0) {
        const validators = this.getValidators()
        this.formControl.setValidators(validators)
      }
    }
  }

  setAnswerAndEmitChange(value: string | string[] | boolean | null, linkId: string) {
    if (!value) return
    if (!Array.isArray(value)) {
      this.questionnaireItem.setAnswer(value)
      this.enableWhenService.updateAnswer(linkId, value.toString())
    } else {
      this.enableWhenService.updateAnswer(linkId, value)
    }
    this.emitQuestionnaireItemChange.emit()
  }

  createButtonElementsForBoolean = [
    {
      label: 'Ja',
      value: 'true',
      disabled: false,
    },
    {
      label: 'Nein',
      value: 'false',
      disabled: false,
    },
  ] as ButtonElementOption[]

  isVertical(questionnaireItem: ActiveQuestionnaireItem) {
    if (questionnaireItem.answerOption?.length) {
      if (questionnaireItem.answerOption[0].valueCoding) {
        if (questionnaireItem.answerOption[0].valueCoding?.display) {
          return questionnaireItem.answerOption[0].valueCoding?.display.length > 56
        }
      }
    }
    return false
  }

  private getValidators() {
    return []
  }

  log($event: Event) {
    console.log($event)
  }

  isEnabled(item: ActiveQuestionnaireItem): boolean {
    if (item.enableWhen?.length) {
      return this.visibilities[item.linkId]
    }
    return true
  }
}
